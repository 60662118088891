/* Search query parameter */
export const getQueryParam = (search = '', param) => {
  if (!param) return null

  if (typeof URLSearchParams !== 'undefined') {
    const searchParams = new URLSearchParams(search)
    if (searchParams.has(param)) {
      return searchParams.get(param)
    }
  } else {
    const queryParam = `${param}=`

    if (search.includes(queryParam)) {
      const [, searchParam] = search
        .substring(1)
        .split('&')
        .find((p) => p.includes(queryParam))
        .split('=')

      return searchParam.length ? decodeURIComponent(searchParam) : null
    }
  }

  return null
}
