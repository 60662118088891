import React from 'react'
import {Helmet} from 'react-helmet'

import {getQueryParam} from '../../utils'
import axios from 'axios'

if (typeof window !== 'undefined' && window.location.pathname.includes('/gdpr/extensions')) {
  window.addEventListener('message', onMessage, true)

  console.log('Waiting for data')

  function onMessage(e) {
    const token = getQueryParam(window.location.search, 'dataSubjectRequest')

    if (e.source !== window || e.data.type !== 'FROM_CONTENT_SCRIPT' || !token) return

    window.removeEventListener('message', onMessage, true)

    console.log('Data received', e.data)

    const {privacyObj} = e.data

    const config = {
      params: {
        dataSubjectRequest: token,
      },
    }

    axios.post('/gdpr/service', privacyObj, config).then(console.log).catch(console.error)
  }
}

export default function GDPR() {
  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
    </>
  )
}
